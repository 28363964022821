import React from "react";
import "../styles/card-service.css";
import { Outlet, Link } from "react-router-dom";

export default function CardService({ page, img, icon, info, styleName }) {
  return (
    <>
      <div className={` col-md-6 col-lg-4 h-100`}>
        <article className="pbminfotech-servicebox-style-5 h-100">
          <div className="pbminfotech-post-item h-100">
            <span className="pbminfotech-item-thumbnail">
              <span className="pbminfotech-item-thumbnail-inner">
                <img src={img} className="img-fluid w-100 h-100" alt="" />
              </span>
            </span>
            <div className="pbmit-ihbox-icon">
              <i
                className={`${icon} pbmit-moversco-business-icon pbmit-moversco-business-icon-shipping-and-delivery-1`}
              ></i>
            </div>
            <div className="pbminfotech-box-content">
              <div className="pbminfotech-box-content-inner">
                <div className="pbminfotech-box-category">
                  <Link to={page} rel="tag">
                    Why choose us?
                  </Link>
                </div>
                <div className="pbminfotech-pf-box-title">
                  <h3>
                    <Link to={page}>{info}</Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>

      <Outlet />
    </>
  );
}
