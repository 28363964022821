import React from "react";

import TitleBar from "../components/TitleBar";
import CardSection from "../components/CardSection";
import WhyChooseUs from "../components/WhyChooseUs";
import Projects from "../components/Projects";
import WhyChooseUs2 from "../components/WhyChooseUs2";
import WhyChooseUs3 from "../components/WhyChooseUs3";
import RequesQuoteSection from "../components/RequesQuoteSection";
import Footer from "../components/Footer";

export default function Homepage() {
  return (
    <>
     
      <TitleBar />
      <CardSection />
      <WhyChooseUs />
      <Projects />
      <WhyChooseUs2 />
      <WhyChooseUs3 />
      <RequesQuoteSection />
      <Footer />
    </>
  );
}
