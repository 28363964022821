import React from 'react'
import '../styles/title-bar.css'

export default function TitleBar() {
  return (
    <div className="pbmit-title-bar-wrapper">
      <div className="container px-4">
        <div className="pbmit-title-bar-content">
          <div className="pbmit-title-bar-content-inner">
            <div className="pbmit-tbar">
              <div className="pbmit-tbar-inner container roller">
                <h5 className="pbmit-tbar-title animate-line one">
                  Let us move your{" "}
                </h5>
                <h5 className="pbmit-tbar-title animate-line  two">
                  goods with <span className="text-span">honesty</span>
                </h5>
                <h5 className="pbmit-tbar-title animate-line three">
                  and greatness.{" "}
                </h5>
              </div>
            </div>
            <div className="pbmit-breadcrumb animate-line four">
              <div className="pbmit-breadcrumb-inner">
                <span>
                  <a title="" href="jj" className="home">
                    <span>SPG</span>
                  </a>
                </span>
                <span className="sep">-</span>
                <span className="post-root post post-post current-item">
                  Trucking & Logistics
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
