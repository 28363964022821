import React from "react";
import commercial from "../assests/commercial.jpg";
import Junk from "../assests/junk-removal.jpg";
import "../styles/why-choose-us-2.css";
import CardService from "./CardService";

export default function WhyChooseUs2() {
  return (
    <section className="service-six" id="services">
      <div className="container">
        <div className="service-six-content">
          <div className="row">
            <div className="col-md-5">
              <div className="pbmit-heading-subheading-style-1">
                <h4 className="pbmit-subtitle">why choose us?</h4>
                <h2 className="pbmit-title">
                  <strong>Safety First</strong>
                </h2>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                We prioritize safety throughout our operations. Our team follows
                strict safety protocols to protect your belongings and our
                staff.
              </p>
            </div>
          </div>
        </div>
        <div className="row card-row">
          <CardService
            page={"./residential"}
            img={
              "https://moversco-demo.pbminfotech.com/html-demo/images/homepage-6/service/service-01.jpg"
            }
            icon={"fa-solid fa-house"}
            info={"Residential Moves"}
            styleName={"card-size"}
          />
          <CardService
            page={"./residential"}
            img={commercial}
            icon={"fa-solid fa-truck-front"}
            info={"Commercial Moves"}
            styleName={"card-size"}
          />
          <CardService
            page={"./residential"}
            img={Junk}
            icon={"fa-solid fa-truck-front"}
            info={"Junk Removal"}
            styleName={"card-size"}
          />
        </div>
      </div>
    </section>
  );
}
