import React from 'react'
import '../styles/movers.css'
import MoveTruck from "../assests/move-truck.webp"
import MoversCard from '../components/MoversCard';
import Footer from '../components/Footer';


export default function Movers() {
  return (
    <>
      <div className="pbmit-title-bar-wrapper general">
        <div className="container">
          <div className="pbmit-title-bar-content">
            <div className="pbmit-title-bar-content-inner" id="about">
              <div className="pbmit-tbar">
                <div className="pbmit-tbar-inner container roller">
                  <h1 className="pbmit-tbar-title fs-1">Moving Locally</h1>
                </div>
              </div>
              <div className="pbmit-breadcrumb">
                <div className="pbmit-breadcrumb-inner">
                  <span>
                    <a title="spg" href="/" className="home">
                      <span>Spg</span>
                    </a>
                  </span>
                  <span className="sep">-</span>
                  <span>
                    <span className="post-root post post-post current-item">
                      Trucking & <span className="logictics">Logistics</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-md" id="services">
        {" "}
        <div className="container">
          <div className="row">
            <MoversCard
              image={
                "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-01.jpg"
              }
              service={"Residential Moves"}
              description={
                "Our residential moving services are designed to make your transition to a new home as smooth as possible"
              }
              pageLink={"/residential"}
              icon={"fa-solid fa-house"}
            />
            <MoversCard
              image={
                "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-04.jpg"
              }
              service={"Commercial Moves"}
              description={
                "Relocating your business? Our commercial moving services are tailored to meet the unique needs of your company."
              }
              pageLink={"/commercial"}
              icon={"fa-solid fa-truck-ramp-box"}
            />
            <MoversCard
              image={
                "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-05.jpg"
              }
              service={"Door to Door Service"}
              description={
                "Our door-to-door moving services provide you with convenience and peace of mind delivery to your new address."
              }
              pageLink={"/door-to-door"}
              icon={"fa-solid fa-thumbs-up"}
            />
            <MoversCard
              image={MoveTruck}
              service={"Trucking Service"}
              description={
                "Our trucking services are the backbone of efficient transportation. Whether you need goods transported locally or across the country."
              }
              pageLink={"/trucking"}
              icon={"fa-solid fa-truck-fast"}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
