import React from 'react'
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";

export default function DoorToDoor() {
  return (
    <>
      <SinglePage
        service={"Door to Door Service"}
        image={
          "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-05.jpg"
        }
        para1={`With our door-to-door services, we handle every aspect of your move, ensuring that your belongings are securely packed, transported, and delivered to your new location. Our experienced movers are equipped to manage every detail.`}
        para2={`At Spg, we value your time and your trust. Our commitment to exceptional service means that we'll go the extra mile to make your move as smooth and stress-free as possible. Experience the convenience of door-to-door moving with us and start your journey to your new destination with confidence.`}
        para3={``}
      />
      <Footer />
    </>
  );
}
