import React from 'react'
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";
import MoveTruck from "../assests/move-truck.webp";

export default function Trucking() {
  return (
    <>
      <SinglePage
        service={"Trucking Service"}
        image={MoveTruck}
        para1={`At Spg trucking & logistics, our trucking services are built on a foundation of reliability and efficiency. We understand the critical role that transportation plays in your supply chain, and we're committed to delivering your goods safely and on schedule.`}
        para2={`Our extensive fleet of trucks is equipped to handle a wide range of cargo, from commercial products to industrial equipment. Whether you have a local delivery or require long-distance transportation, we have the right vehicles and experienced drivers to get the job done.`}
        para3={`What sets us apart is our dedication to transparency and communication. We provide real-time tracking and updates on the status of your shipments, so you're always informed about the progress of your deliveries. Our commitment to excellence in transportation extends to every aspect of our service.`}
      />
      <Footer />
    </>
  );
}
