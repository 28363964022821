import React from 'react';
import '../styles/card-section.css'
import Card from './Card'

export default function CardSection() {
  return (
    <section className="icon-box-six">
      <div className="container-fluid">
        <div className="row">
          <Card
            icon={"fa-solid fa-truck"}
            header={"Make your Reservation"}
            content={
              "Reserve your truck mover now for a seamless relocation experience."
            }
          />
          <Card
            icon={"fa-solid fa-truck"}
            header={"Load the truck with items."}
            content={
              "Let us handle the heavy lifting, safely load your items in the truck."
            }
          />
          <Card
            icon={"fa-solid fa-house"}
            header={"We'll take it from there"}
            content={"Leave it to us, we'll handle the rest of the journey."}
          />
        </div>
      </div>
    </section>
  );
}
