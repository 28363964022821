import React from 'react'

export default function WhyCard({header, icon, description}) {
  return (
    <div className="pbminfotech-ihbox-style-12">
      <div className="pbminfotech-ihbox-inner">
        <div className="pbminfotech-ihbox-icon pbminfotech-large-icon">
          <div className="pbminfotech-ihbox-icon-wrapper">
            <i
              className={`${icon} pbmit-moversco-business-icon pbmit-moversco-business-icon-truck`}
            ></i>
          </div>
        </div>
        <div className="pbminfotech-ihbox-contents">
          <div className="pbminfotech-vc_general pbminfotech-vc_cta3">
            <div className="pbminfotech-vc_cta3_content-container">
              <div className="pbminfotech-vc_cta3-content">
                <div className="pbminfotech-vc_cta3-content-header pbminfotech-wrap">
                  <div className="pbminfotech-vc_cta3-headers pbminfotech-wrap-cell">
                    <h2 className="pbminfotech-custom-heading">{header}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pbminfotech-cta3-content-wrapper">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
}
