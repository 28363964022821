// InfoBox.js
import React from "react";

const InfoBox = ({ title, icon }) => {
  return (
    <div className="col-md-6 col-lg-4">
      <div className="pbminfotech-ihbox-style-9 focus">
        <div className="pbminfotech-ihbox-inner">
          <div className="pbminfotech-ihbox-icon pbminfotech-large-icon pbminfotech-icon-skincolor">
            <div className="pbminfotech-ihbox-icon-wrapper">
              <i className={`pbmit-moversco-business-icon ${icon}`}></i>
            </div>
          </div>
          <div className="pbminfotech-ihbox-contents">
            <div className="pbminfotech-vc_general pbminfotech-vc_cta3">
              <div className="pbminfotech-vc_cta3_content-container">
                <div className="pbminfotech-vc_cta3-content">
                  <div className="pbminfotech-vc_cta3-content-header pbminfotech-wrap">
                    <div className="pbminfotech-vc_cta3-headers pbminfotech-wrap-cell">
                      <h2 className="pbminfotech-custom-heading">{title}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
