import React from "react";
import "../styles/card.css";

export default function CardContent({ header }) {
  
  return (
    <div className="pbminfotech-vc_cta3-content">
      <div className="pbminfotech-vc_cta3-content-header pbminfotech-wrap">
        <div className="pbminfotech-vc_cta3-headers pbminfotech-wrap-cell">
          <h2 className="pbminfotech-custom-heading">{header}</h2>
        </div>
      </div>
    </div>
  );
}
