import React, { useEffect, useRef } from "react";
import "../styles/projects.css";
import ProjectCard from "./ProjectCard";

export default function Projects() {
  const countingSectionRef = useRef(null);

  useEffect(() => {
    const startCountingAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const numinateElements = entry.target.querySelectorAll(".numinate");
          numinateElements.forEach((element) => {
            const endValue = element.getAttribute("data-end");
            let currentValue = 0;

            const updateValue = () => {
              if (currentValue <= endValue) {
                element.textContent = currentValue;
                currentValue += 1; 
                requestAnimationFrame(updateValue);
              }
            };

            requestAnimationFrame(updateValue);
          });
          // Unobserve the section once animation is triggered
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(startCountingAnimation, {
      root: null, // Viewport
      rootMargin: "0px",
      threshold: 0.1, // 10% of the section in the viewport triggers animation
    });

    // Observe the counting section
    observer.observe(countingSectionRef.current);

    // Cleanup the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section className="counting-section" ref={countingSectionRef}>
      <div className="container counter-six-box px-0">
        <div className="counter-six">
          <div className="row g-0">
            <ProjectCard end={252} info={"Finished Projects"} />
            <ProjectCard end={2} info={"Years of Experience"} />
            <ProjectCard end={399} info={"Happy client"} />
            <ProjectCard end={3} info={"Range of Services"} />
          </div>
        </div>
      </div>
    </section>
  );
}
