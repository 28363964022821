import React from "react";
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";

export default function Residential() {
  return (
    <>
      <SinglePage
        service={"Residential Moves"}
        image={
          "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-01.jpg"
        }
        para1={`Moving to a new home can be a significant life event, and we understand the emotional and logistical challenges that come with it. That's why our residential moving services are not just about physically relocating your possessions; they are about easing the entire process. Our dedicated team takes the time to listen to your specific needs and concerns, ensuring a personalized approach to your move.`}
        para2={`Whether you're moving locally or across state lines, our experienced team is well-equipped to handle your move. For local relocations, we're intimately familiar with the area, ensuring timely arrivals and efficient unloading.`}
        para3={`Moving is a new beginning, and we're here to help you start this exciting chapter in your life. With our residential moving services, you can focus on settling into your new home, while we take care of the rest. We pride ourselves on delivering not just your possessions but also peace of mind, making your move a memorable and positive experience.`}
      />
      <Footer />
    </>
  );
}
