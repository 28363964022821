import React, { useState } from "react";
import "../styles/success-message.css";

export default function SuccessMessage({ message1, message2, onClose }) {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    visible && (
      <div className="success-message">
        <div className="success-content">
          <p className="text-info">{message1}</p>
          <p className="text-info">{message2}</p>
          <button onClick={handleClose}>OK</button>
        </div>
      </div>
    )
  );
}
