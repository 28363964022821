import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage";
import Header from "./components/Header";
import GeneralFreight from "./pages/GeneralFreight";
import Junk from "./pages/Junk";
import Movers from "./pages/Movers";
import Residential from "./pages/Residential";
import Commercial from "./pages/Commercial";
import DoorToDoor from "./pages/DoorToDoor";
import Trucking from "./pages/Trucking";
import NoPage from "./pages/NoPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Homepage />} />
          <Route path="/general" element={<GeneralFreight />} />
          <Route path="/junk" element={<Junk />} />
          <Route path="/movers" element={<Movers />} />
          <Route path="/residential" element={<Residential />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/door-to-door" element={<DoorToDoor />} />
          <Route path="/trucking" element={<Trucking />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
