import React from "react";
import "../styles/card.css";
import CardContent from "./CardContent";

export default function Card({ icon, header, content }) {
  return (
    <div className="col-md-6 col-xl-4">
      <div className="pbminfotech-ihbox-style-11">
        <div className="pbminfotech-ihbox-inner">
          <div className="pbminfotech-ihbox-icon pbminfotech-large-icon">
            <div className="pbminfotech-ihbox-icon-wrapper">
              <i
                className={`${icon} pbmit-moversco-business-icon pbmit-moversco-business-icon-truck`}
              ></i>
            </div>
          </div>
          <div className="pbminfotech-ihbox-contents">
            <div className="pbminfotech-vc_general pbminfotech-vc_cta3">
              <div className="pbminfotech-vc_cta3_content-container">
                <CardContent header={header} />
              </div>
            </div>
            <div className="pbminfotech-cta3-content-wrapper mt-2">
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
