import React from "react";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../styles/header.css";
import "../styles/layout.css";
import logo from "../assests/SPGp.png";
// import Layout from "./Layout";

export default function Header() {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  // Define paths where the button should be hidden
  const pathsToHideButton = [
    "/general",
    "/junk",
    "/movers",
    "/residential",
    "/commercial",
    "/door-to-door",
    "/trucking",
  ];

  // Check the window scroll position
  const handleScroll = () => {
    if (window.scrollY > 30 && window.innerWidth > 1000) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className="site-header header-style-6 active">
        <div className="pbmit-header-overlay">
          <div className="site-header-menu-wrapper">
            <div
              className={`${isSticky ? "sticky-header" : " site-header-menu"} `}
            >
              <div className="container p-0 h-100">
                <div className="row g-0 h-100 w-100">
                  <div className="col-md-12 h-100 w-100">
                    <div className="pbmit-header-content align-items-center h-100">
                      {/* <Layout /> */}
                      <nav className="navbar navbar-expand-lg w-100 h-100">
                        <div className="container-fluid site-branding">
                          <Link className="navbar-brand" to="/">
                            <img className="logo-img" src={logo} alt="" />
                          </Link>
                          <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <span className="navbar-toggler-icon"></span>
                          </button>
                          <div className="pbmit-mobile-menu-bg"></div>
                          <div
                            className="collapse navbar-collapse"
                            id="navbarNav"
                          >
                            <div className="">
                              <ul className="navbar-nav navigation clearfix">
                                <li className="nav-item active">
                                  <Link
                                    className="nav-link active"
                                    aria-current="page"
                                    to="/"
                                  >
                                    Home
                                  </Link>
                                </li>
                                <li className="nav-item dropdown">
                                  <Link
                                    className="nav-link dropdown-toggle"
                                    to="/services"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Services
                                  </Link>
                                  <ul
                                    className={`${
                                      isSticky
                                        ? "dropdown-menu rounded-0 navfull"
                                        : "dropdown-menu rounded-0"
                                    }`}
                                  >
                                    <li>
                                      <Link
                                        className="dropdown-item active"
                                        to="/general"
                                      >
                                        General freight
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/junk"
                                      >
                                        Junk removal
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/movers"
                                      >
                                        Moving Locally
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#contact">
                                    Contact
                                  </a>
                                </li>
                                {pathsToHideButton.includes(
                                  location.pathname
                                ) ? null : (
                                  <li className="nav-item">
                                    <a className="nav-link" href="#quote">
                                      <button className="btn rounded-0 p-2">
                                        <span>REQUEST A QUOTE</span>
                                      </button>
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  );
}
