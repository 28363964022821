import React from 'react'
import SinglePage from "../components/SinglePage";
import junk from "../assests/junk-removal.jpg";
import Footer from "../components/Footer";

export default function Junk() {
  return (
    <>
      <SinglePage
        service={"Junk Removal"}
        image={junk}
        para1={`Are you tired of clutter and unwanted items taking up valuable space in your home or office? Look no further! Spg trucking & logistics is here to provide you with top-notch junk removal services throughout the United States. We are your local experts in decluttering and reclaiming your space, one junk item at a time.`}
        para2={`We are committed to responsible disposal. We make every effort to recycle and donate items whenever possible, reducing the impact on the environment.`}
        para3={`Our pricing is fair and transparent. You'll receive a clear estimate before we start any work, so there are no surprises.`}
      />
      <Footer />
    </>
  );
}
