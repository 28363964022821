import React from "react";
import InfoBox from "./InfoBox";

export default function Info() {
  return (
    <>
      <div className="col-md-6">
        <div className="pbmit-heading-subheading-style-1 focus">
          <h4 className="pbmit-subtitle">focused on quality</h4>
          <h2 className="pbmit-title">
            Global Logistics
            <br /> Partner To Many <br />
            <strong>
              Famous Brands <br />
              From Over 2 Years!
            </strong>
          </h2>
        </div>
      </div>
      <div className="col-md-6">
        <div className="appointment-four-ihbox">
          <div className="row">
            <InfoBox
              title={"Transparent pricing"}
              icon={"fa-solid fa-business-time"}
            />
            <InfoBox
              title={"Fast, efficient delivery"}
              icon={"fa-solid fa-truck-fast"}
            />
            <InfoBox
              title={"Reliability"}
              icon={"fa-solid fa-handshake-angle"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
