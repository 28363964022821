import React from "react";
import SinglePage from "../components/SinglePage";
import Footer from "../components/Footer";

export default function Commercial() {
  return (
    <>
      <SinglePage
        service={"Commercial Moves"}
        image={
          "https://moversco-demo.pbminfotech.com/html-demo/images/service/service-04.jpg"
        }
        para1={`At Spg trucking & logistics, we recognize that relocating a business involves much more than just moving physical items. It's about ensuring that your operations continue seamlessly during the transition. Our dedicated team of commercial movers is well-versed in the intricacies of corporate relocations.`}
        para2={`From office furniture to sensitive equipment and confidential files, we handle every aspect of your move with the utmost care and efficiency. We work closely with you to create a customized moving plan that fits your specific needs. `}
        para3={`With Spg trucking & logistics, you can trust that your commercial move will be handled professionally and with minimal disruption, so you can quickly resume normal business operations. Your success is our priority, and we're here to support your business every step of the way.`}
      />
      <Footer />
    </>
  );
}
