import React from "react";
import "../styles/single-page.css";
import { Link } from "react-router-dom";

export default function SinglePage({ service, image, para1, para2, para3 }) {
  return (
    <>
      <div className="pbmit-title-bar-wrapper general">
        <div className="container">
          <div className="pbmit-title-bar-content">
            <div className="pbmit-title-bar-content-inner">
              <div className="pbmit-tbar">
                <div className="pbmit-tbar-inner container roller">
                  <h1 className="pbmit-tbar-title fs-1">{service}</h1>
                </div>
              </div>
              <div className="pbmit-breadcrumb">
                <div className="pbmit-breadcrumb-inner">
                  <span>
                    <a title="spg" href="/" className="home">
                      <span>Spg</span>
                    </a>
                  </span>
                  <span className="sep">-</span>
                  <span>
                    <span className="post-root post post-post current-item">
                      Trucking & <span className="logictics">Logistics</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 service-left-col order-2 order-lg-1">
              <aside className="service-sidebar">
                <aside className="widget post-list">
                  <div className="all-post-list">
                    <ul>
                      <li>
                        <Link to="/residential"> Residential Moves </Link>
                      </li>

                      <li className="post-active">
                        <Link to="/commercial"> Commercial Moves </Link>
                      </li>
                      <li>
                        <Link to="/door-to-door"> Door to Door Service </Link>
                      </li>
                      <li>
                        <Link to="/junk"> Junk Removal </Link>
                      </li>

                      <li>
                        <Link to="/general"> General Frieght </Link>
                      </li>
                      <li>
                        <Link to="/trucking"> Trucking Service </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
                <aside className="widget post-list">
                  <div className="textwidget">
                    <div className="single-service-contact">
                      <div className="single-service-contact-inner">
                        <span>
                          <i className="pbmit-moversco-business-icon-headset fa-solid fa-headset"></i>
                        </span>
                        <h3>How Can We Help</h3>
                        <ul>
                          <li>
                            <i className="pbmit-base-icon-phone fa-solid fa-phone"></i>{" "}
                            (612) 261-6394
                          </li>
                          <li>
                            <i className="pbmit-base-icon-paper-plane-empty fa-solid fa-envelope"></i>{" "}
                            spgtruckingl@gmail.com
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </aside>
              </aside>
            </div>
            <div className="col-lg-8 service-right-col order-1" id="about">
              <img src={image} className="w-100" alt="" />
              <div className="service-details">
                <h4 className="pbmit-title">Service Overview</h4>
                <p>{para1}</p>
                <p>{para2}</p>

                <div className="service-single-content">
                  <p>{para3}</p>
                  <div className="row" id="services">
                    <div className="col-md-4">
                      <h6 className="pbmit-heading">Safety First</h6>
                      <p>
                        We prioritize safety throughout our operations. Our team
                        follows strict safety protocols to protect your
                        belongings and our staff.
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h6 className="pbmit-heading">Professional Team</h6>
                      <p>
                        We prioritize your satisfaction. Our friendly and
                        professional staff are here to address your specific
                        needs and concerns, making your experience hassle-free.
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h6 className="pbmit-heading">Eco-Friendly</h6>
                      <p>
                        We're committed to environmentally responsible
                        practices. We dispose of junk responsibly and aim to
                        reduce our carbon footprint.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
