import React from "react";
import "../styles/why3.css";
import ProgressBar from "./ProgressBar";

export default function WhyChooseUs3() {
  return (
    <section className="progress-six">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 p-0">
            <img
              src="https://moversco-demo.pbminfotech.com/html-demo/images/homepage-6/img-02.png"
              className="w-100"
              alt=""
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="progress-six-box">
              <div className="pbmit-heading-subheading-style-1">
                <h4 className="pbmit-subtitle">why choose us?</h4>
                <h2 className="pbmit-title">
                  <strong>Versatility</strong>
                </h2>
              </div>
              <p>
                We offer a wide range of services, from transporting your
                freight to decluttering your space through junk removal and
                providing reliable moving solutions. No task is too big or small
                for us.
              </p>

              <ProgressBar label="General Freight" percent={90} />
              <ProgressBar label="Movers" percent={80} />
              <ProgressBar label="Junk Removal" percent={70} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
