import React from "react";

export default function ProjectCard({end, info}) {
  return (
    <div className="col-md-3">
      <div className="pbminfotech-fidbox-style-7">
        <div className="pbminfotech-fld-contents">
          <div className="pbminfotech-fid-inner">
            <span className="numinate completed" data-end={end}>
              0
            </span>
          </div>
          <h3 className="pbminfotech-fid-title">
            <span>
              {info}
              <br />
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
}
