import React from 'react'
import '../styles/movers-card.css'
import { Link } from "react-router-dom"

export default function MoversCard({image, service, description, pageLink, icon}) {
  return (
    <div className="col-md-6 col-lg-4">
      <article className="pbminfotech-servicebox-style-1">
        <div className="pbminfotech-post-item">
          <span className="pbminfotech-item-thumbnail">
            <span className="pbminfotech-item-thumbnail-inner">
              <img src={image} className="img-fluid" alt="" />
            </span>
          </span>
          <div className="pbminfotech-box-content">
            <div className="pbminfotech-box-content-inner">
              <div className="pbminfotech-pf-box-title">
                <div className="pbmit-ihbox-icon">
                  <i
                    className={`${icon} pbmit-moversco-business-icon pbmit-moversco-business-icon-shipping-and-delivery-1`}
                  ></i>
                </div>
                <h3>
                  <Link to={pageLink}>{service}</Link>
                </h3>
                <div className="pbminfotech-service-content">
                  <p>{description}</p>
                </div>
                <div className="pbminfotech-box-link">
                  <Link to={pageLink}>
                    READ MORE
                    <i class="fas fa-arrow-right arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}
