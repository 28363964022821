import React, { useState } from "react";
import SuccessMessage from "./SuccessMessage";

export default function QuoteForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "Service",
    departureCity: "",
    deliveryCity: "",
    address: "",
    zipcode: "",
  });

  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  // function to post the form details to getform
  const formSubmit = async (formData) => {
    await fetch("https://getform.io/f/71563eec-c16b-4eae-916c-cb7e6df7456f", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFormData = new FormData(e.target);
    formSubmit(newFormData);
    let valid = true;
    const newErrors = {};

    if (!formData.name) {
      valid = false;
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      valid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      valid = false;
      newErrors.email = "Invalid email format";
    }

    if (!formData.phone) {
      valid = false;
      newErrors.phone = "Phone is required";
    }

    if (formData.service === "Service") {
      valid = false;
      newErrors.service = "Please select a service";
    }

    setErrors(newErrors);

    if (valid) {
      // // call the getform endpoint
      // formSubmit(formData);

      // clear the input fields
      setFormData({
        name: "",
        email: "",
        phone: "",
        service: "Service",
        departureCity: "",
        deliveryCity: "",
        address: "",
        zipcode: "",
      });

      // Show success message
      setShowSuccess(true);
    }
  };
  return (
    <div className="tab-four-box">
      <div className="container">
        <div className="pbmit-tab-style-1">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#tab-2-1"
                aria-selected="true"
                role="tab"
              >
                <span>Request a quote</span>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane show active" id="tab-2-1" role="tabpanel">
              <div className="pbmit-column-inner">
                <div className="row">
                  <div className="col-md-8">
                    <form
                      className="form-style-2"
                      onSubmit={handleSubmit}
                      method="POST"
                      encType="multipart/form-data"
                      acceptCharset="UTF-8"
                    >
                      <div className="row">
                        <h3>Personal Data</h3>
                        <div className="col-lg-4 col-md-12">
                          {/* display error */}
                          <div className="error">
                            {errors.name && (
                              <span className="error">{`${errors.name}*`}</span>
                            )}
                          </div>
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                            className="form-control"
                            placeholder="Name*"
                          />
                        </div>
                        <div className="col-lg-4 col-md-12">
                          {/* display error */}
                          <div className="error">
                            {errors.email && (
                              <span className="error">{`${errors.email}*`}</span>
                            )}
                          </div>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            placeholder="Email*"
                          />
                        </div>
                        <div className="col-lg-4 col-md-12">
                          {/* display error */}
                          <div className="error">
                            {errors.phone && (
                              <span className="error">{`${errors.phone}*`}</span>
                            )}
                          </div>
                          <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phone: e.target.value,
                              })
                            }
                            className="form-control"
                            placeholder="Phone*"
                          />
                          {/* display error */}
                        </div>
                      </div>
                      <div className="row">
                        <h3>Service Details</h3>

                        <div className="col-lg-6 col-md-12">
                          <input
                            type="text"
                            name="departureCity"
                            value={formData.departureCity}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                departureCity: e.target.value,
                              })
                            }
                            className="form-control"
                            placeholder="City of Departure"
                          />
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <input
                            type="text"
                            name="deliveryCity"
                            value={formData.deliveryCity}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                deliveryCity: e.target.value,
                              })
                            }
                            className="form-control"
                            placeholder="Delivery City"
                          />
                        </div>
                        <div className="col-lg-4 col-md-12">
                          {/* display error */}
                          <div className="error">
                            {errors.service && (
                              <span className="error">{`${errors.service}*`}</span>
                            )}
                          </div>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="service"
                            value={formData.service}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                service: e.target.value,
                              })
                            }
                          >
                            <option value="service">Service</option>
                            <option value="general-freight">
                              General Freight
                            </option>
                            <option value="junk-removal">Junk Removal</option>
                            <option value="residential">
                              Residential Moves
                            </option>
                            <option value="door-to-door">
                              Door to Door Moves
                            </option>
                            <option value="commercial">Commercial Moves</option>
                            <option value="transportation">
                              Trucking Service
                            </option>
                          </select>
                        </div>

                        <div className="col-lg-8 col-md-12">
                          <div className="row">
                            <div className="col-lg-8 col-md-12">
                              <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    address: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Address"
                              />
                            </div>
                            <div className="col-lg-4 col-md-12">
                              <input
                                type="text"
                                name="zipcode"
                                value={formData.zipcode}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    zipcode: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Zip Code"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="pbmit-btn pbmit-btn-hover-global"
                          >
                            Request A Quote
                          </button>
                        </div>
                      </div>
                    </form>
                    {showSuccess && (
                      <SuccessMessage
                        message1={"Spg has received your quote requirement."}
                        message2={"We will give you a call shortly. Thank you!"}
                        onClose={handleSuccessClose}
                      />
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="single-image-wrapper">
                      <img
                        src="https://moversco-demo.pbminfotech.com/html-demo/images/homepage-4/form-img.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
