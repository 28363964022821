import React from 'react'
import '../styles/nopage.css'

export default function NoPage() {
  return (
    <div className="nopage">
      <h1>404</h1>
 
      <h4>Not Found</h4>
     
      <p>The resource requested could not be found on this server!</p>
    </div>
  );
}
