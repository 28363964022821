import React from 'react'

const ProgressBar = ({ label, percent }) => {
  return (
    <div className="progressbar pbmit-progress-style-2">
      <span className="progress-label">{label}</span>
      <div className="progress progress-lg progress-percent-bg">
        <div
          className="progress-bar"
          style={{ width: `${percent}%` }}
          aria-valuenow={percent}
          aria-valuemin="0"
          aria-valuemax="100"
        >
        </div>
        <span className="progress-percent">{percent}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;