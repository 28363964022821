import React from "react";
import "../styles/request-quote.css";
import Info from "./Info";
import QuoteForm from "./QuoteForm";

export default function RequesQuoteSection() {
  return (
    <section id="quote">
      <div className="appointment-four-bg pbmit-heading-color">
        <div className="container">
          <div className="row">
            <Info />
          </div>
        </div>
      </div>
      <QuoteForm />
    </section>
  );
}
