import React from "react";
import SinglePage from "../components/SinglePage";
import movers from "../assests/move-truck.webp";
import Footer from "../components/Footer";

export default function GeneralFreight() {
  return (
    <>
      <SinglePage
        service={"General Freight"}
        image={movers}
        para1={`At Spg trucking & logistics, we specialize in interstate transportation solutions that cater to the diverse needs of businesses across the country. Our General Freight service is the cornerstone of our logistics capabilities, offering a reliable and efficient way to move goods between states.`}
        para2={`With our vast fleet of well-maintained trucks and a team of experienced drivers, we ensure that your cargo or items are transported seamlessly across state lines. Whether you're shipping goods to neighboring states or far we have the resources and expertise to get the job done.
`}
        para3={`Choosing Spg for your General Freight for Interstates means partnering with a trusted logistics provider. We have a proven track record of delivering goods safely cause, we adhere to strict safety standards and regulations to ensure the secure transportation of your goods.and efficiently, helping businesses thrive by ensuring their products reach their destination on time.`}
      />
      <Footer />
    </>
  );
}
