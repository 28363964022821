import React from 'react'
import { Link, Outlet, useLocation } from "react-router-dom";
import '../styles/footer.css'
import Logo from '../assests/SPGp.png'

export default function Footer() {
  const location = useLocation();

  // Define paths where the button should be hidden
  const pathsToHideButton = [
    "/general",
    "/junk",
    "/movers",
    "/residential",
    "/commercial",
    "/door-to-door",
    "/trucking",
  ];
  return (
    <footer
      className="footer site-footer pbmit-bg-color-secondary"
      id="contact"
    >
      <div className="pbmit-footer-widget-area-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="pbmit-footer-boxes">
                <div className="pbmit-footer-contact-info">
                  <div className="pbmit-footer-contact-info-inner">
                    <i className="themifyicon ti-headphone fa-solid fa-location-dot"></i>
                  </div>
                  <div className="pbmit-footer-contact-info-wrap">
                    <strong>Address:</strong> <br />
                    Minnesota, USA
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-footer-boxes">
                <div className="pbmit-footer-contact-info">
                  <div className="pbmit-footer-contact-info-inner">
                    <i className="themifyicon ti-email fa-solid fa-envelope"></i>
                  </div>
                  <div className="pbmit-footer-contact-info-wrap">
                    <strong>E-mail: </strong> <br />
                    Fester@spgtl.com
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pbmit-footer-boxes">
                <div className="pbmit-footer-contact-info">
                  <div className="pbmit-footer-contact-info-inner">
                    <i className="themifyicon ti-email fa-solid fa-phone"></i>
                  </div>
                  <div className="pbmit-footer-contact-info-wrap">
                    <strong>Phone: </strong> <br />
                    (612) 261-6394
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pbmit-footer-widget-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="widget">
                <div className="textwidget">
                  <p>
                    <img className="pbmit-footerlogo" src={Logo} alt="" />
                  </p>
                  <p>
                    We offer a wide range of services, from transporting your
                    freight to decluttering your space through junk removal and
                    providing reliable moving solutions. No task is too big or
                    small for us.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="widget">
                <h2 className="widget-title">QUICK LINKS</h2>
                <div className="textwidget">
                  <ul>
                    <li>
                      <a href="#about">About Us</a>
                    </li>
                    <li>
                      <a href="#services">Our Services</a>
                    </li>
                    <li>
                      <a href="#contact">Get In Touch</a>
                    </li>
                    {pathsToHideButton.includes(location.pathname) ? null : (
                      <li>
                        <a href="#quote">Request a quote</a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="widget">
                <h2 className="widget-title">CATEGORIES</h2>
                <div className="textwidget">
                  <ul>
                    <li>
                      <Link to="/general">General Freight</Link>
                    </li>
                    <li>
                      <Link to="/commercial">Commercial Moves</Link>
                    </li>
                    <li>
                      <Link to="/residential">Residential Moves</Link>
                    </li>
                    <li>
                      <Link to="/junk">Junk Removal</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pbmit-footer-bottom">
        <div className="container">
          <div className="pbmit-footer-text-inner text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="pbmit-footer-copyright-text-area">
                  {" "}
                  Copyright © 2023
                  <a href="/">Spg Trucking & Logistics</a>. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </footer>
  );
}
