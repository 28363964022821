import React from "react";
import "../styles/why-choose-us.css";
import WhyCard from "./WhyCard";

export default function WhyChooseUs() {
  return (
    <section className="about-section-six" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-6">
            <img
              src="https://moversco-demo.pbminfotech.com/html-demo/images/homepage-6/img-01.png"
              className="img-fluid w-100"
              alt=""
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="about-six-content">
              <div className="pbmit-heading-subheading-style-1">
                <h4 className="pbmit-subtitle">why choose us?</h4>
                <h2 className="pbmit-title">
                  <strong>
                    We give you complete control of your shipment.
                  </strong>
                </h2>
              </div>
              <p>
                We offer a wide range of services, from transporting your
                freight to decluttering your space through junk removal and
                providing reliable moving solutions.
              </p>
              <WhyCard
                header={"Expertise"}
                icon={"fa-solid fa-truck"}
                description={`
                      With years of experience in general freight, junk removal,
                      and moving services, we handle your items with care and
                      efficiency.`}
              />

              <WhyCard
                header={"Competitive Pricing"}
                icon={"fa-solid fa-truck-ramp-box"}
                description={`
                      We offer competitive rates without compromising on quality. You'll get value for your money with our cost-effective solutions.`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
